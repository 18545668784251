<template>
  <div class="row justify-content-center">
    <div class="col-12">
      <div class="card card-custom">
        <div class="card-body p-0">
          <!--begin: Wizard-->
          <div
            class="wizard wizard-1"
            id="kt_wizard_v1"
            data-wizard-state="step-first"
            data-wizard-clickable="true"
          >
            <!--begin: Wizard Body-->
            <div
              class="row justify-content-center my-10 px-8 my-lg-15 px-lg-10"
            >
              <div class="col-xl-12">
                <!--begin: Wizard Form-->

                <!--begin: Wizard Step 1-->
                <div
                  class="pb-5"
                  data-wizard-type="step-content"
                  data-wizard-state="current"
                >
                  <h5
                    class="font-weight-bolder text-dark font-size-h6 font-size-h3-lg"
                  >
                    Detail Pertemuan Daring
                  </h5>
                  <table class="table table-borderless mt-8">
                    <tr>
                      <th class="pr-1" width="100">
                        <span>Nama</span>
                      </th>
                      <th class="pl-0 pr-0" width="10">
                        <span>:</span>
                      </th>
                      <td class="pl-2">
                        <span >{{ detail.name }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th class="pr-0">
                        <span>API</span>
                      </th>
                      <th class="pl-0">
                        <span>:</span>
                      </th>
                      <td class="pl-2">
                        <span >{{ detail.api }}</span>
                      </td>
                    </tr>
                  </table>
                </div>
                <!--end: Wizard Step 1-->

                <!--begin: Wizard Actions -->
                <div class="d-flex justify-content-between border-top pt-10">
                  <div>
                    <b-button
                      type="button"
                      class="ml-2"
                      variant="primary"
                      @click="$router.push('/masters/meeting-platforms')"
                    >
                      Tutup
                    </b-button>
                    <b-button
                      type="button"
                      class="ml-2"
                      variant="success"
                      @click="$router.push('/masters/meeting-platforms/edit/' + $route.params.id)"
                      v-if="btnAccess"
                    >
                      Edit
                    </b-button>
                  </div>
                </div>
                <!--end: Wizard Actions -->
                <!--end: Wizard Form-->
              </div>
            </div>
            <!--end: Wizard Body-->
          </div>
        </div>
        <!--end: Wizard-->
      </div>
    </div>
  </div>
</template>

<style>
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service.js";

export default {
  name: "master-meeting-platforms",
  data() {
    return {
      detail: {},
      // access
      btnAccess: false,
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Master", route: "/masters/settings" },
      { title: "Pertemuan Daring", route: "/masters/meeting-platforms" },
      { title: "Detail Pertemuan Daring" },
    ]);

    this.getDataById();
    this.checkAccess()
  },
  methods: {
    getDataById() {
      ApiService.get("api/meeting-platforms/" + this.$route.params.id).then(
        (response) => {
          //(response);
          this.detail = response.data.data;
        }
      );
    },
    // access
    checkAccess() {
      let access_right_user = window.localStorage.getItem("access_right_display")
      let access_right = JSON.parse(access_right_user)
      for (let a = 0; a < access_right.length; a++) {
        if (access_right[a] == "9012") {
          this.btnAccess = true
        }
      }
    },
  },
};
</script>
